
import {
  apiAccountLists,
  apiAccountDelete,
  apiAccountReset
} from '@/api/account'
import { Component, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
@Component({
  components: {
    lsPagination,
    LsDialog
  }
})
export default class AccountList extends Vue {
  /** S Data **/

  apiAccountLists = apiAccountLists;

  pager: RequestPaging = new RequestPaging()
  /** E Data **/

  // 获取管理员列表
  getAccountLists(): void {
    this.pager.request({
      callback: apiAccountLists
    })
  }

  // 删除商城
  async onAccountDelete(row: any): Promise<void> {
    await apiAccountDelete({ id: row.id })
    this.getAccountLists()
  }

  // 去编辑商城
  toAccountEdit(id: number | any, reset = false): void {
    if (reset) {
      apiAccountReset({ id }).then((res) => {
        this.getAccountLists()
      })
    } else {
      this.$router.push({
        path: '/setting/account/lists_detail',
        query: {
          id: id
        }
      })
    }
  }

  /** E Methods **/

  /** S ods **/
  created() {
    this.getAccountLists()
  }
}
